import { render } from "./imgsComp.vue?vue&type=template&id=5772698e&scoped=true"
import script from "./imgsComp.vue?vue&type=script&lang=ts"
export * from "./imgsComp.vue?vue&type=script&lang=ts"

import "./imgsComp.vue?vue&type=style&index=0&id=5772698e&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5772698e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5772698e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5772698e', script)) {
    api.reload('5772698e', script)
  }
  
  module.hot.accept("./imgsComp.vue?vue&type=template&id=5772698e&scoped=true", () => {
    api.rerender('5772698e', render)
  })

}

script.__file = "src/views/leaflets/components/imgsComp.vue"

export default script