
export default{
  name: 'imgsComp',
  props: {
    imgs: Array
  },
	setup(props: any){
		// let imgList = props && props.imgs
    // return {
    //   imgList
    // }
	}
}
