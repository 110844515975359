<template>
  <template v-if="isVertical">
    <div class="footer vertical-footer" @touchmove.prevent>
      <div class='info'>
        <p>
          <span class="code">¥</span>
          <span class="price">2099</span>
          <span class="icon">
          </span>
        </p>
        <p class="tips">预计8月底发货</p>
      </div>
    <div class="btn" @click="appoint">立即预约</div>
    </div>
  </template>
  <template v-else>
    <div class="footer horizontal-footer">
      <div @click="appoint" class="hor-btn"></div>
    </div>
  </template>
</template>
<script lang="ts">
import { getCurrentInstance } from 'vue'
import { track } from '@/utils/nativeBridge'

export default ({
  props: {
    isVertical: Boolean
  },
  setup (props: any) {
    const { proxy } = getCurrentInstance() as any
    const appoint = function() {
      track({
        eventName: 'ai_eastwind_element_click',
        jsonData: {
          tab_name: `H5-${props.isVertical? '竖版' : '横版'}-钢琴灯介绍页-立即预约按钮`
        }
      })
      proxy.toast({
        text: '预约成功',
        isVertical: props.isVertical
      });
    }
    return {
      appoint
    }
  }
})
</script>
<style lang="scss" scoped>
.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
}
.vertical-footer{
  padding: 0 3vw 0 6vw;
  align-items: center;
  justify-content: space-between;
	width: 100%;
	height: 21vw;
	background-color: #fff;
	box-shadow: 0vw -1vw 1vw 0vw rgba(0, 0, 0, 0.05);
}
.horizontal-footer{
  right: 0;
  bottom: 3vw;
  width: 37vw;
  height: 6vw;
  margin: 0 auto;
  border-radius: 3vw;
  background: url('/images/leaflets/footer_btn.png') no-repeat center;
  background-size: 100%;
  z-index: 99;
  .hor-btn{
    width: 50%;
    height: 6vw;
    margin-left: 50%;
  }
}
@media screen and (min-width: 1000px){
  .horizontal-footer{
    width: 55.5vw;
    height: 7.8vw;
  }
  .hor-btn{
    height: 7.8vw!important;
  }
}

.info{
  font-size: 0;
}
.code{
  color: #f65951;
  font-size: 4vw;
  font-family: PingFangSC-Semibold;
}
.price{
  margin-left: 1vw;
	font-size: 9vw;
	color: #f65951;
  line-height: 1;
  font-family: PingFangSC-Semibold;
  font-weight: 700;
}
.icon{
  display: inline-block;
  width: 14vw;
	height: 6.25vw;
  background: url('/images/leaflets/icon.png') no-repeat center;
  background-size: 100%;
  font-size: 0;
  vertical-align: top;
  margin-top: -1.1vw;
  margin-left: 1vw;
}
.tips{
	font-size: 3vw;
  color: rgba(102, 102, 102, 0.8);
}
.btn{
  width: 43vw;
	height: 14vw;
	background-color: #f65951;
	border-radius: 7vw;
  line-height: 14vw;
  text-align: center;
  color: #fff;
  font-family: PingFangSC-Semibold;
  font-weight: 700;
	font-size: 5vw;
}
</style>