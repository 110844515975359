const imgUrl = 'http://ai-score-library.oss-cn-beijing.aliyuncs.com/img_prod/ms_lt_'
// 线上 / 测试
const bannerimgs = {
  'horizontal': [
    `${imgUrl}horizontal_01.png`
  ]
}

const detailImgs = {
  'vertical' : [
    `${imgUrl}vertical_01.png`,
    `${imgUrl}vertical_02.png`,
    `${imgUrl}vertical_03.png`,
    `${imgUrl}vertical_04.png`,
    `${imgUrl}vertical_05.png`,
    `${imgUrl}vertical_06.png`,
    `${imgUrl}vertical_07.png`,
    `${imgUrl}vertical_08.png`
  ],
  'horizontal': [
    `${imgUrl}horizontal_02.png`,
    `${imgUrl}horizontal_03.png`,
    `${imgUrl}horizontal_04.png`,
    `${imgUrl}horizontal_05.png`,
    `${imgUrl}horizontal_06.png`,
    `${imgUrl}horizontal_07.png`,
    `${imgUrl}horizontal_08.png`
  ]
}
const swiperImgs = {
  'horizontal' : [
    `${imgUrl}horizontal_09_1.png`,
    `${imgUrl}horizontal_09_2.png`,
    `${imgUrl}horizontal_09_3.png`,
    `${imgUrl}horizontal_09_4.png`,
    `${imgUrl}horizontal_09_5.png`
  ],
  'vertical': [
    `${imgUrl}vertical_09_1.png`,
    `${imgUrl}vertical_09_2.png`,
    `${imgUrl}vertical_09_3.png`,
    `${imgUrl}vertical_09_4.png`,
    `${imgUrl}vertical_09_5.png`
  ]
}
const otherImgs = {
  'vertical' : [
    `${imgUrl}vertical_10.png`,
    `${imgUrl}vertical_11.png`
  ],
  'horizontal': [
    `${imgUrl}horizontal_10.png`,
    `${imgUrl}horizontal_11.png`
  ]
}

const tips = [
  '1.把灯放置距离边缘3-4cm处（或钢琴的音箱铰链处），按下电源键。',
  '2.连接数据线',
  '3.打开小叶子APP选择曲目，点击识谱',
  '4.进行琴键检测',
  '5.开始练琴，指法出错时智能纠正'
]

const pageTitle = '小叶子AI钢琴灯'

export default {
  bannerimgs,
  detailImgs,
  swiperImgs,
  otherImgs,
  tips,
  pageTitle
}