
import { getCurrentInstance } from 'vue'
import { track } from '@/utils/nativeBridge'

export default ({
  props: {
    isVertical: Boolean
  },
  setup (props: any) {
    const { proxy } = getCurrentInstance() as any
    const appoint = function() {
      track({
        eventName: 'ai_eastwind_element_click',
        jsonData: {
          tab_name: `H5-${props.isVertical? '竖版' : '横版'}-钢琴灯介绍页-立即预约按钮`
        }
      })
      proxy.toast({
        text: '预约成功',
        isVertical: props.isVertical
      });
    }
    return {
      appoint
    }
  }
})
