<template>
<div class="swiper-wrap" :class="[isVertical ? 'vertical' : 'horizontal']">
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in imgs" :key="item">
        <img :src="item" alt="">
      </div>
    </div>
  </div>
  <div class="bar">
    <div class="bar-slider" v-for="(item, index) in imgs" :key=item :class="[activeIndex === index + 1 ? 'active': '']"></div>
  </div>
</div>
</template>
<script lang="ts">
import Swiper from 'swiper'
import { onMounted, reactive, toRefs, nextTick } from 'vue'

export default{
  name: 'swiperImgs',
  props: {
    imgs: Array,
    isVertical: Boolean
  },
	setup(props: any){
    let state = reactive({
      activeIndex: 0
    })

    let imgsListLen = 0 // swiper图片的个数

    function handleSwiper() {
      new Swiper('.swiper-container', {
        loop: true,
        observer: true,
        observeParents: true,
        on: {
          transitionEnd(val) {
            let that = this as any
            let index = that.activeIndex
            if (index > imgsListLen) {
              state.activeIndex = 1
            } else if (index <= 0) {
              state.activeIndex = imgsListLen
            } else {
              state.activeIndex = index
            }
          },
        }
      })
    }
    onMounted(async () => {
      imgsListLen = props && props.imgs.length
      await nextTick()
      handleSwiper()
    })
    return {
      ...toRefs(state),
    }
	}
}
</script>

<style lang="scss" scoped>
.swiper-container{
  width: 89vw;
  height: 50vw;
  border-radius: 3vw;
  border: solid 0.02rem #179059;
  overflow: hidden;
  margin: 0 auto;
}
.bar{
  display: flex;
  font-size: 0;
  margin-top: 4vw;
  justify-content: center;
  .bar-slider{
    display: inline-block;
    width: 8vw;
    height: 0.05rem;
    background-color: #E2F4EC;
  }
  .bar-slider{
    margin-left: 2vw;
  }
  .active{
    background-color: #179059;
  }
}
.horizontal {
  .swiper-container{
    border: solid 0.018rem #179059;
    box-sizing: content-box;
    border-radius: 1vw;
  }
  .swiper-container{
    width: 39vw;
    height: 22vw;
  }
  .bar-slider{
    width: 3vw;
    height:0.0209rem;
    margin-left: 1vw;
  }
}
</style>
