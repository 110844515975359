
import Swiper from 'swiper'
import { onMounted, reactive, toRefs, nextTick } from 'vue'

export default{
  name: 'swiperImgs',
  props: {
    imgs: Array,
    isVertical: Boolean
  },
	setup(props: any){
    let state = reactive({
      activeIndex: 0
    })

    let imgsListLen = 0 // swiper图片的个数

    function handleSwiper() {
      new Swiper('.swiper-container', {
        loop: true,
        observer: true,
        observeParents: true,
        on: {
          transitionEnd(val) {
            let that = this as any
            let index = that.activeIndex
            if (index > imgsListLen) {
              state.activeIndex = 1
            } else if (index <= 0) {
              state.activeIndex = imgsListLen
            } else {
              state.activeIndex = index
            }
          },
        }
      })
    }
    onMounted(async () => {
      imgsListLen = props && props.imgs.length
      await nextTick()
      handleSwiper()
    })
    return {
      ...toRefs(state),
    }
	}
}
