import { render } from "./swiperComp.vue?vue&type=template&id=a7fc8bbe&scoped=true"
import script from "./swiperComp.vue?vue&type=script&lang=ts"
export * from "./swiperComp.vue?vue&type=script&lang=ts"

import "./swiperComp.vue?vue&type=style&index=0&id=a7fc8bbe&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-a7fc8bbe"
/* hot reload */
if (module.hot) {
  script.__hmrId = "a7fc8bbe"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a7fc8bbe', script)) {
    api.reload('a7fc8bbe', script)
  }
  
  module.hot.accept("./swiperComp.vue?vue&type=template&id=a7fc8bbe&scoped=true", () => {
    api.rerender('a7fc8bbe', render)
  })

}

script.__file = "src/views/leaflets/components/swiperComp.vue"

export default script