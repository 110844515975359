<template>
  <!-- 视频 -->
  <div class="video-container" :class="[isVertical ? 'vertical-video-container' : 'horizontal-video-container']">
    <video id='startvideo' ref="video" controls="controls"
      :src=url
      :poster='poster'
      webkit-playsinline="true"
      playsinline="true"
      preload = 'none'
      autoplay
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      >
    </video>
  </div>
</template>
<script lang="ts">
export default{
  name: 'videoComp',
  props: {
    imgs: Array,
    isVertical: Boolean
  },
	setup(props: any){
    // muted
    // setTimeout(() => {
    //   let video: any = document.getElementById('startvideo'); 
    //   video.play();
    // }, 1000)
    return {
      poster: 'http://ai-score-library.oss-cn-beijing.aliyuncs.com/img_prod/ms_lt_video_poster.png',
      url: 'https://oss-ai-peilian-app.xiaoyezi.com/pre/img//all_type_file/469d854fbffe89bedb15030ff0157b60.mp4'
    }
	}
}
</script>

<style lang="scss" scoped>
.video-container{
  width: 100%;
  background-color: #222;
  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.vetical-video-container{
  height: 56vw;
  // height: 100vh;
}
.horizontal-video-container{
  height: 100%;
}
</style>