
export default{
  name: 'videoComp',
  props: {
    imgs: Array,
    isVertical: Boolean
  },
	setup(props: any){
    // muted
    // setTimeout(() => {
    //   let video: any = document.getElementById('startvideo'); 
    //   video.play();
    // }, 1000)
    return {
      poster: 'http://ai-score-library.oss-cn-beijing.aliyuncs.com/img_prod/ms_lt_video_poster.png',
      url: 'https://oss-ai-peilian-app.xiaoyezi.com/pre/img//all_type_file/469d854fbffe89bedb15030ff0157b60.mp4'
    }
	}
}
