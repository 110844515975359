<template>
  <div v-for="item in imgs" :key=item class="imgs">
    <img :src="item" alt="The ONE">
  </div>
</template>
<script lang="ts">
export default{
  name: 'imgsComp',
  props: {
    imgs: Array
  },
	setup(props: any){
		// let imgList = props && props.imgs
    // return {
    //   imgList
    // }
	}
}
</script>

<style lang="scss" scoped>
.imgs{
  width: 100%;
  font-size: 0;
  img{
    display: block;
    width: 100%;
    object-fit: cover;
  }
}
</style>
