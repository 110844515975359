<template>
  <div class="top-bar-wrap"  v-show="isshow && upwardScroll">
    <div class="top-bar-container" :class="[isBigBar? 'isBig': 'isLittle']">
      <!-- 有白色背景的标题栏 -->
      <div class="back" @click.stop = 'back'></div>
      <h1 class="title">
        {{ title }}
      </h1>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { back } from '@/utils/nativeBridge'

export default {
  props: {
    isshow: Boolean,
    title: String,
    isBigBar: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    let state = reactive({
      upwardScroll: true // 向上滚动时候展示
    });
    let initScrop = 0
    window.addEventListener('scroll', _ => {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop // 页面滚动的高度
      let flag = true
      if (scrollTop <= 100) {
        flag = true
      } else {
        if (scrollTop - initScrop >= 0) {
          flag = false
        } else {
          flag = true
        }
      }
      initScrop = scrollTop
      state.upwardScroll = flag
    })
    
    return {
      back,
      ...toRefs(state)
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar-wrap{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background-color: #fff;
}
.top-bar-container {
  display: flex;
  align-items: center;
}
.title{
  font-family: PingFangSC-Medium;
  flex: 1;
  text-align: center;
  line-height: 1;
}
.back{
  background: url('http://ai-score-library.oss-cn-beijing.aliyuncs.com/img_prod/ms_lt_back.png') no-repeat center;
  background-size: 100%;
}
// 白色背景的样式
.isBig{
  // min-height: 12vw;
  padding: 3vw 4vw;
  .title{
    color: #444444;
    font-size: 5vw;
  }
  .back{
    width: 6vw;
    height: 6vw;
  }
}
.isLittle{
  height: 6vw;
  padding: 0 4vw;
  .title{
    font-size: 2vw;
    color: #222222;
  }
  .back{
    width: 3vw;
    height: 3vw;
  }
}
</style>
