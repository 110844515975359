/* 退出当前页面 */
import closeWebview from './closeWebview'
import { getUrlParam } from './location'
interface IParams{
  type?: string,
  name?: string,
  obj?: any
}

function callback(params: IParams = {}) {
  console.log('native_callback: ', params)
  const nativeCallback = (window as any).native_callback
  const paramsToJsonString: any = JSON.stringify(params)

  try {
    nativeCallback(paramsToJsonString);
  } catch (error) {
    console.log('不是app环境')
  }
}

const typeMap = {
  'back': 'evtBack',
  'track': 'onTrack' // 暂时先不用
}

interface ITrackParams{
  eventName?: string,
  jsonData?: any
}
// 埋点
const track = function(params: ITrackParams = {}) {
  const eventName = params.eventName
  const jsonDataObj = (params && params.jsonData) || {}
  const jsonData = JSON.stringify(jsonDataObj)
  callback({
    type: 'event',
    name: typeMap.track,
    obj: JSON.stringify({
      eventName,
      jsonData
    })
  })
}

// 退出H5
const back = function() {
  console.log('关闭')
  if (getUrlParam('h5Jump')) {
    window.history.back()
  } else {
    closeWebview('xyz://onClose')
  }
}

export {
  back,
  track
}
